import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { useState, useEffect } from "react"
import React from "react"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    menuOpen: false,
    henrikCount: 0,
})

//Funksjon som sjekker om siden er lastet eller ikke. Litt usikker på hvordan denne funker egentlig, hehe
function pageLoading() {
    const [isLoading, setLoading] = React.useState(true)
    React.useEffect(() => setLoading(false), [])
    return isLoading ? true : false
}

//Funksjon som henter menybaren og menyen og bringer dem til front når menyen åpnes. I tillegg disabler den scroll på siden når menybaren åpnes.
function StopPageScrollOnMenuOpen(store) {
    const body = document.getElementsByTagName("body") //Hent bodyelementet

    store.menuOpen
        ? (body[0].style.overflow = "hidden") //Hvis meny er åpen, disable scroll
        : (body[0].style.overflow = "visible") //Hvis meny er lukket, enable scroll

    body[0].style.scrollbarGutter = "stable"
}

export function withMenuOverlay(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        const smileys = [
            ":)",
            "(:",
            ":o",
            "(ㆆ _ ㆆ)",
            "( ͡° ᴥ ͡°)",
            "(⌐■_■)",
            "ヽ༼ຈل͜ຈ༽ﾉ",
            "(ﾉ◕ヮ◕)ﾉ*:・ﾟ✧",
        ]

        useEffect(() => {
            setTimeout(() => {
                console.log("Hei Halogen " + smileys[store.henrikCount])
                setStore({
                    henrikCount:
                        store.henrikCount >= smileys.length - 1
                            ? 0
                            : store.henrikCount + 1,
                })
            }, 5000)
        }, [store.henrikCount])

        //Sjekk om siden er ferdig lastet. Uten denne krasjer komponenten under framer sin optimaliserings-greie :(
        if (!pageLoading()) {
            StopPageScrollOnMenuOpen(store) //Hvis siden er lastet inn, kjør funksjonen som henter body og disabler scroll når menyen åpnes
        }

        //console.log(menuContainer)

        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    /*
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "sticky",
                    height: "100vh",
                    width: "100vw",
                    
                    position: "absolute !important",
                    top: "0p !important",
                    left: "0px !important",
                    */
                    height: "100vh",
                    width: "100vw",
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "sticky",
                    top: "0px",
                    left: "0px",
                    /*zIndex: store.menyOpen ? "5" : "-5",*/
                    display: store.menuOpen ? "flex" : "none",
                    //scrollbarGutter: "stable",
                }}
            />
        )
    }
}

export function withOpenMenuOnPress(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        /*console.log(store.menuOpen)*/
        return (
            <Component
                {...props}
                onClick={() => {
                    setStore({ menuOpen: store.menuOpen ? false : true })
                }}
                variant={store.menuOpen ? "Close" : "Open"}
            />
        )
    }
}

export function withCloseMenuOnPress(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        /*console.log(store.menuOpen)*/
        return (
            <Component
                {...props}
                onClick={() => {
                    setStore({ menuOpen: false })
                }}
            />
        )
    }
}

export function withShowOnMenuOpen(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        /*console.log(store.menuOpen)*/
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    display: store.menuOpen ? "block" : "none",
                }}
            />
        )
    }
}

export function withChangeThemeColorInHead(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        //henter bakgrunnsfargen fra komponenten og splitter den opp i rgb-verdier
        const backgroundColor = props.background
            .match(/(?<=rgb\().+?(?=\))/)[0]
            .split(",")

        const overlayColor = props.overlayBackground
            .match(/(?<=rgb\().+?(?=\))/)[0]
            .split(",")

        //console.log(overlayColor)

        //sjekker om siden er lastet
        if (!pageLoading()) {
            //Hent header-elementet på siden
            const head = document.getElementsByTagName("head")[0]

            //Hent metataggen for theme-color
            var metaThemeColorElement = document.querySelector(
                "meta[name=theme-color]"
            )

            //Hvis meta-elementet med theme-color ikke eksisterer
            if (!metaThemeColorElement) {
                //Lag meta element med theme-color
                var meta = document.createElement("meta")
                meta.name = "theme-color"
                meta.content = "#ffffff" //Placeholderfarge

                //Legg til metaelementet i head på siden
                document.getElementsByTagName("head")[0].appendChild(meta)

                //Hent meta-elementet på nytt
                metaThemeColorElement = document.querySelector(
                    "meta[name=theme-color]"
                )
            }

            if (store.menuOpen) {
                //Hvis menyen er åpen, sett theme-color til bakgrunnsfargen på menyoverlayen
                metaThemeColorElement.setAttribute(
                    "content",
                    "rgb(" +
                        overlayColor[0] +
                        "," +
                        overlayColor[1] +
                        "," +
                        overlayColor[2] +
                        ")"
                )
            } else {
                //Hvis menyen er lukket, sett theme-color til bakgrunnen på meny-baren
                metaThemeColorElement.setAttribute(
                    "content",
                    "rgb(" +
                        backgroundColor[0] +
                        "," +
                        backgroundColor[1] +
                        "," +
                        backgroundColor[2] +
                        ")"
                )
            }
        }

        return <Component {...props} />
    }
}
